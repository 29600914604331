const tempElement = `<div class='development-error'><b>Deprecated</b>:  Return type of Twig\\Node\\Node::count() should either be compatible with Countable::count(): int, or the #[\\ReturnTypeWillChange] attribute should be used to temporarily suppress the notice in <b>/mnt/volume_fra1_02/cosylab.specto.work/public/wp-content/plugins/timber-library/vendor/twig/twig/src/Node/Node.php</b> on line <b>213</b><br></div>`;

const removeDevelopmentError = (closeAll = false) => {
  if (closeAll) {
    document.body.style.overflow = '';
    document.querySelectorAll('.development-error__wrapper').forEach((error) => error.remove());
    return;
  }

  const error = document.querySelector('.development-error__wrapper:not(.is-hidden)');
  const nextError = document.querySelector('.development-error__wrapper.is-hidden');

  // Hide the element and remove it from the DOM
  error.classList.add('is-hidden');
  setTimeout(() => error.remove(), 1000);

  // Show next error
  setTimeout(() => {
    if (nextError) {
      nextError.classList.remove('is-hidden');
    }

    document.body.style.overflow = nextError ? 'hidden' : '';
  }, 150);
};

const addDevelopmentEvents = () => {
  const closeButtons = document.querySelectorAll('.development-error__close');
  const closeAllButtons = document.querySelectorAll('.development-error__close-all');

  const closeAll = () => {
    const errorElements = document.querySelectorAll('.development-error__wrapper');
    errorElements.forEach(() => removeDevelopmentError(true));
  };

  // Handle close button
  closeButtons.forEach((close) => close.addEventListener('click', () => removeDevelopmentError(false)));

  // Handle close all button
  closeAllButtons.forEach((close) => close.addEventListener('click', () => closeAll()));

  // Add escape key event
  document.addEventListener('keydown', (event) => {
    if (event.key === 'Escape') {
      closeAll();
    }
  });
};

document.addEventListener('DOMContentLoaded', () => {
  // document.body.innerHTML = tempElement + document.body.innerHTML; // For testing purposes only

  const errors = document.querySelectorAll('.development-error');
  document.body.style.overflow = errors.length ? 'hidden' : '';

  errors.forEach((error, index) => {
    error.className = `development-error__wrapper ${index !== 0 ? 'is-hidden' : ''}`;
    error.innerHTML = `
      <div class='development-error__backdrop'></div>

      <div class='development-error'>
          <div class='development-error__content'>
            <div class='development-error__banner'></div>
            <div class='development-error__header'>
              <h4 class='development-error__title'>Development error</h4>
              <button class='development-error__close'>&times;</button>
            </div>

            <div class='development-error__body'>
              <div class='development-error__terminal'>
                  <pre>${error.innerHTML}</pre>
              </div>
              <footer>
                  <small>This error occurred during the development process and will not be seen in production.
                      <span>(error ${index + 1}/${errors.length})</span>
                  </small>
                  <button class='development-error__close-all'>
                    <small>Close all</small>
                  </button>
              </footer>
            </div>
          </div>
      </div>
`.trim();
  });

  addDevelopmentEvents();
});
