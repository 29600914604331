/* global attachEvent */
// *******************************************************************************
// 1.0 - Gallery Modal
// *******************************************************************************

const removeActiveSlide = () => {
  const activeSlide = document.querySelector('.team-module__modal-slide.is-active');
  activeSlide?.classList.remove('is-active');
};

const removeModal = () => {
  removeActiveSlide();
  document.body.classList.remove('open-team-modal');
};
const addModal = () => {
  document.body.classList.add('open-team-modal');
};

attachEvent('.team-module__item-click', 'click', (ev) => {
  removeActiveSlide();

  const targetItem = ev.target.closest('.team-module__item').getAttribute('data-modalslide');
  addModal();
  document.querySelector(`.${targetItem}`)?.classList.add('is-active');
});

const updateActiveSlide = (next) => {
  const activeSlide = document.querySelector('.team-module__modal-slide.is-active');
  if (activeSlide) {
    const targetSlide = next ? activeSlide.nextElementSibling : activeSlide.previousElementSibling;

    if (targetSlide) {
      activeSlide.classList.remove('is-active');
      targetSlide.classList.add('is-active');
    }
  }
};

attachEvent('.arrow-right', 'click', () => {
  updateActiveSlide(true);
});

attachEvent('.arrow-left', 'click', () => {
  updateActiveSlide(false);
});

document.addEventListener('keydown', (event) => {
  if (event.key === 'ArrowRight') {
    updateActiveSlide(true);
  }
  if (event.key === 'ArrowLeft') {
    updateActiveSlide(false);
  }
});

// *******************************************************************************
// 2.0 - Close Gallery-modal
// *******************************************************************************

attachEvent('.team-module__modal-close', 'click', () => {
  removeModal();
});

document.addEventListener('keydown', (event) => {
  if (event.key === 'Escape') {
    removeModal();
  }
});

const pageHeader = document.getElementById('page-header');
pageHeader?.addEventListener('click', () => {
  removeModal();
});
